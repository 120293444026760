import { Routes, Route } from "react-router-dom";
import Card from "../pages/Card";
import SplashScreen from "../pages/SplashScreen";

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="*" element={<SplashScreen />} />
      <Route path="/card" element={<Card />} />
    </Routes>
  );
};

export default AppNavigator;
