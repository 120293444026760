import "./styles.scss";
import React, { useEffect, useState } from "react";
import startup_logo from "../../assets/startup_logo.jpg";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

function SplashScreen() {
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    if (clicked === true) {
      setTimeout(() => {
        setHidden(true);
      }, 400);
    }
  }, [clicked]);

  useEffect(() => {
    if (hidden === true) {
      setTimeout(() => {
        navigate("/card");
      }, 400);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setShowIndicator(true);
    }, 2000);
  }, []);

  return (
    <div className="splash-screen">
      <div
        className={`splash-screen-container ${
          hidden ? "splash-screen-hidden" : ""
        }`}
        onClick={() => setClicked(!clicked)}
      >
        <div
          className={`splash-screen-inner ${
            clicked ? "splash-screen-transform" : ""
          }`}
        >
          <div className="splash-screen-front">
            <div
              className={`splash-screen-overlay ${
                showIndicator
                  ? "splash-screen-overlay-show"
                  : "splash-screen-overlay-hidden"
              }`}
            >
              <Button>
                <p>
                  Mazal tov !<br />
                  Cliquez ici !
                </p>
              </Button>
            </div>
            <img
              src={startup_logo}
              alt="startup logo"
              height={"90%"}
              className="splash-screen-img"
            />
          </div>
          <div className="splash-screen-back"></div>
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
